import {useAppsStore} from "../Stores/AppsStore";
import {useCampaignStore} from "../Stores/CampaignStore";
import {useStepEditStore} from "../Stores/StepEditStore";
import {useStepsStore} from "../Stores/StepsStore";
import {useComponentEditStore} from "../Stores/ComponentEditStore";

export function getStoreByName(name) {
    switch (name) {
        case 'AppsStore': return useAppsStore();

        case 'campaign':
        case 'CampaignStore': return useCampaignStore();

        case 'StepEditStore': return useStepEditStore();
        case 'StepsStore' : return useStepsStore();

        case 'ComponentEditStore': return useComponentEditStore();
    }

    throw new Error("Could not find Store with the name " + name);
}

export function expandFields(obj, reactiveFields) {
    let expanded = {};
    for (let i in obj) {
        if (reactiveFields.includes(i)) {
            expanded[i] = {value: obj[i], loading: false, hint: null, errors: []};
        } else {
            expanded[i] = obj[i];
        }

    }
    return expanded;
}

export function getExpandedField(all, field_identifier) {
    try {
        return all[field_identifier.id][field_identifier.field];
    } catch (e) {
        if (e instanceof TypeError) {
            // Anweisungen für die verschiedenen Fehlertypen
            console.error(`ID ${field_identifier.id} nicht mehr im Store`);
        } else if (e instanceof RangeError) {
            // Anweisungen für Bereichsfehler
        } else if (e instanceof EvalError) {
            // Anweisungen für Berechnungsfehler
        } else {
            // Anweisungen für nicht festgelegte Fehlertypen
            console.error(e); // Objekt an die Fehler-Funktion geben
        }
    }
}
