
export function required(value) {
    return !!value || 'Wird benötigt';
}

export function max35(value) {
    return value.length <= 35 || 'Max 35 Zeichen';
}

export function slug(value) {
    const pattern = /^([a-z0-9\-_]+)$/;
    return pattern.test(value) || 'Kein gültiger Slug';
}

export function host(value) {
    const pattern = /^([a-z0-9\-_.]+)$/;
    return pattern.test(value) || 'Kein gültiger Host';
}

export function email(value) {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || 'Keine gültige E-Mail Adresse';
}

export function url(value) {
    const pattern = /^https?:\/\//;
    return pattern.test(value) || 'Keine gültige Url';
}