import {defineStore} from 'pinia';
import * as api from "../services/api";
import {usePage} from "@inertiajs/inertia-vue3";
import {useCampaignStore} from "./CampaignStore";
import {parseBool} from "../utils/helpers";

export const useStepEditStore = defineStore('StepEditStore', {
    state: () => {
        return {
            processing: false,
            uuid: null,
            label: null,
            lib: [],
            include: [],
            custom_css: null,
            script: [],
            content: {},
            editor_config: {},
            window_items: [],
            current_window: 0,
            current_window_props: null,
            window_items_opened: [],
            window_props_history: [],
        };
    },
    getters: {
        editableForCurrentUser: (state) => {
            // Empty editor_config
            if ( ! state.editor_config || Array.isArray(state.editor_config) && state.editor_config.length === 0) return false;
            const user = usePage().props.value.auth.user;

            // Check for "operator_only" flag
            if (Object.hasOwn(state.editor_config, 'operator_only')) {
                if (parseBool(state.editor_config.operator_only)) {
                    return user.operator;
                }
            }

            return true;
        }
    },
    actions: {
        reset() {
            this.processing = false;
            this.uuid = null;
            this.label = null;
            this.lib = [];
            this.include = [];
            this.custom_css = null;
            this.script = [];
            this.content = {};
            this.editor_config = {};
            this.window_items = [];
            this.current_window = 0;
            this.current_window_props = null;
            this.window_items_opened = [];
            this.window_props_history = [];
        },
        parseEditorConfig() {
            try {
                this.window_items = [];
                if (this.editor_config && this.editor_config.items) {
                    this.extractSubPanel(this.editor_config.items, this.window_items);
                } else {
                    this.editor_config = null;
                }
            } catch (e) {
                console.log(e);
                this.window_items = [];
            }
        },
        /**
         * extract all sub panels and create a flat array for v-window/v-window-item usage
         *
         * Recursive Function!
         *
         * @param elements
         * @param panels
         * @returns {number}
         */
        extractSubPanel(elements, panels) {
            const items = [];
            let panel_index = panels.push(items) - 1;

            elements.forEach(e => {
                if (e.component === 'SubPanel') {
                    items.push({
                        "component": "SubPanel",
                        "label": e.label,
                        "hint": e.hint,
                        "json_path": e.json_path,
                        "component_config": {
                            ...e.component_config,
                            "window_target_index": this.extractSubPanel(e.items, panels),
                        },
                        "vuetify_config": e.vuetify_config,
                    });
                } else {
                    items.push(e);
                }
            });

            return panel_index;
        },
        openSubPanel(panel_props) {
            this.window_items_opened.unshift(panel_props.window_index);
            this.current_window_props = panel_props;
            this.window_props_history.unshift(panel_props);

            // For Vuetify
            this.current_window = panel_props.window_index;
        },
        closeSubPanel() {
            // Shift current
            this.window_props_history.shift();
            this.window_items_opened.shift();

            // Set to previous settings
            this.current_window_props = this.window_props_history[0] || null;
            this.current_window = this.window_items_opened[0] || 0;
        },
        /**
         * save
         * - 99% of the time this will be "content"
         * - 1% it will be "custom_css"
         *
         * @param field
         * @returns {*}
         */
        save(field) {
            // Fallback to "content"
            if ( ! field) field = "content";

            const payload = {label: this.label};
            payload[field] = this[field];

            return new Promise((resolve, reject) => {
                this.processing = true;
                const url = window.route('steps.update', {step: this.uuid});

                api.client.post(url, payload).then(response => {
                    // Show "saved" Feedback only if we actually saved!
                    if (response.data.saved) {
                        const campaignStore = useCampaignStore();
                        campaignStore.snackbarText = 'Änderung gespeichert';
                        campaignStore.showSnackbar = true;
                    }

                    resolve(response.data.step);
                }).catch(error => {
                    console.log(error);
                    reject();
                }).finally(() => {
                    this.processing = false;
                });
            });
        },
        getAssetUploadUrl() {
            return window.route('assets.storeForStep', {step: this.uuid});
        },
        getAssetDeleteUrl(json_path) {
            return window.route('assets.deleteCustomised', {
                step: this.uuid,
                json_path: json_path,
            });
        }
    }
});