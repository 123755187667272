<template>
    <v-combobox v-model="value" :label="props.label" v-bind="props.vuetify_config"></v-combobox>
</template>

<script setup>
import {computed} from "vue";
// https://www.npmjs.com/package/jsonpath
import jp from "jsonpath";
import {mandatory_props} from "../../utils/editorConfigFieldHelpers";
import {getStoreByName} from "../../utils/storeHelpers";

const props = defineProps(mandatory_props);
const store = getStoreByName(props.storeName);

/**
 * dynamic json_path!
 * If this Component lives *within* a repeatable SubPanel, we need to find the current index
 * The json_path from the config will not be complete, hence we build it here
 */
const jsonPath = computed(() => {
    if (store.current_window_props && store.current_window_props.json_path) {
        return jp.stringify([
            ...store.current_window_props.json_path.split('.'),
            store.current_window_props.repeater_index,
            props.json_path
        ]);
    }

    return props.json_path;
});

const value = computed({
    get() {
        return jp.value(store.content, jsonPath.value);
    },
    set(v) {
        jp.value(store.content, jsonPath.value, v);
    }
});
</script>

<style scoped>

</style>