<template>
    <div class="mb-6 basic-typography">
        <h3 class="mb-1">{{ props.label }}</h3>
        <tip-tap v-model="value" />
    </div>
</template>

<script setup>
import {computed} from "vue";
import TipTap from "../TipTap";
// https://www.npmjs.com/package/jsonpath
import jp from "jsonpath";
import {mandatory_props} from "../../utils/editorConfigFieldHelpers";
import {getStoreByName} from "../../utils/storeHelpers";

const props = defineProps(mandatory_props);
const store = getStoreByName(props.storeName);

/**
 * dynamic json_path!
 * If this Component lives *within* a repeatable SubPanel, we need to find the current index
 * The json_path from the config will not be complete, hence we build it here
 */
const jsonPath = computed(() => {
    if (store.current_window_props && store.current_window_props.json_path) {

        let my_json_path = [props.json_path];
        if (props.json_path.includes('.')) {
            my_json_path = props.json_path.split('.');
        }

        return jp.stringify([
            ...store.current_window_props.json_path.split('.'),
            store.current_window_props.repeater_index,
            ...my_json_path
        ]);
    }

    return props.json_path;
});
const value = computed({
    get() {
        return jp.value(store.content, jsonPath.value);
    },
    set(v) {
        jp.value(store.content, jsonPath.value, v);
    }
});
</script>

<style scoped>

</style>
