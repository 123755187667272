
export const mandatory_props = {
    json_path: {
        type: String,
    },
    label: {
        type: String,
        required: true,
    },
    hint: {
        type: String,
    },
    component_config: {
        type: Object,
    },
    vuetify_config: {
        type: Object,
    },
    storeName: {
        type: String,
        default: 'StepEditStore'
    }
};