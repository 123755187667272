<template>
    <v-card v-if="!isRepeatable" class="my-6" @click="handleSubPanelOpen">
        <v-card-title>
            {{ props.label }} <v-icon>mdi-chevron-right</v-icon>
        </v-card-title>
        <v-card-text v-if="props.hint">{{ props.hint }}</v-card-text>
    </v-card>

    <!-- The Content is repeatable ///////////////////////////////////////////////////////////////////////////////// -->
    <v-card v-else>
        <v-card-title>
            {{ props.label }}
        </v-card-title>
        <v-card-subtitle v-if="props.hint">{{ props.hint }}</v-card-subtitle>
        <v-card-text>

            <!-- Draggable repeatable -->
            <draggable :list="value"
                       :item-key="((item) => value.indexOf(item))"
                       :disabled="!child_sortable"
                       handle=".drag-handle">
                <template #item="{element, index}">


                    <v-card class="mb-2">
                        <template v-slot:prepend v-if="child_sortable">
                            <v-icon  class="drag-handle">mdi-drag-vertical</v-icon>
                        </template>

                        <template v-slot:title>
                            <div class="cursor-pointer" @click="handleSubPanelOpenWithProps({repeater_index: index})">
                                {{ getFirstChildLabel(index) }}<v-icon>mdi-chevron-right</v-icon>
                            </div>
                        </template>

                        <template v-slot:append v-if="child_deletable">
                            <v-icon @click.self="removeFromList(index)">mdi-trash-can-outline</v-icon>
                        </template>
                    </v-card>


                </template>
            </draggable>


        </v-card-text>
        <v-card-actions v-if="child_creatable">
            <v-btn icon="mdi-plus-circle"
                   @click="addToList" color="primary"
                   class="mx-auto" />
        </v-card-actions>
    </v-card>

</template>

<script setup>
import draggable from "vuedraggable";
// https://www.npmjs.com/package/jsonpath
import jp from "jsonpath";
import {mandatory_props} from "../../utils/editorConfigFieldHelpers";
import {getStoreByName} from "../../utils/storeHelpers";
import {computed, unref} from "vue";

const props = defineProps(mandatory_props);
const store = getStoreByName(props.storeName);
const isRepeatable = computed(() => {
    return Array.isArray(value.value);
});

/**
 * dynamic json_path!
 * If this Component lives *within* a repeatable SubPanel, we need to find the current index
 * The json_path from the config will not be complete, hence we build it here
 */
const jsonPath = computed(() => {
    if (store.current_window_props && store.current_window_props.json_path) {

        let my_json_path = [];
        if (props.json_path) {
            my_json_path = jp.parse(props.json_path).map(e => e.expression.value);
        }

        const path = [
            ...jp.parse(store.current_window_props.json_path).map(e => e.expression.value),
            store.current_window_props.repeater_index,
            ...my_json_path
        ];

        try {
            return jp.stringify(path);
        } catch (e) {
            console.log("Could not stringify json_path", ...path);
            console.log(e);
        }
    }

    return props.json_path;
});

const value = computed({
    get() {
        if (jsonPath.value) {
            return jp.value(store.content, jsonPath.value);
        }
        return null;
    },
    set(v) {
        if (jsonPath.value) {
            jp.value(store.content, jsonPath.value, v);
        }
    }
});

const child_creatable = computed(() => {
    if (props.component_config) {
        if (props.component_config.child_creatable === false) return false;
    }
    return true;
});
const child_sortable = computed(() => {
    if (props.component_config) {
        if (props.component_config.child_sortable === false) return false;
    }
    return true;
});
const child_deletable = computed(() => {
    if (props.component_config) {
        if (props.component_config.child_deletable === false) return false;
    }
    return true;
});

function handleSubPanelOpenWithProps(myprops) {
    store.openSubPanel({
        window_index: props.component_config.window_target_index,
        json_path: jsonPath.value,
        ...myprops,
    });
}

function handleSubPanelOpen() {
    store.openSubPanel({
        window_index: props.component_config.window_target_index,
        window_title: props.label,
    });
}


function getFirstChildLabel(index) {
    let label = props.label + " " + (index + 1);

    try {
        let path = jsonPath.value + "["+index+"]." + props.component_config.child_label;
        let child_label = jp.value(store.content, path);
        if (child_label) return child_label;
    } catch (e) {
        console.log(e);
    }

    return label;
}

/**
 * add to list and directly open it!
 * - Try to find out which kind of new item we need
 * - Check if the component_config has a "child_blueprint"
 */
function addToList() {
    let new_item = {};

    // If the list is new (i.e. an empty array) initialize a new one!
    if ( ! Array.isArray(value.value)) {
        value.value = [];
    }

    if (props.component_config.child_blueprint) {
        new_item = Object.assign({}, JSON.parse(JSON.stringify(props.component_config.child_blueprint)));
    } else {
        // If there are already items in the List,
        // try to re-create an items but with empty values
        if (value.value.length > 0) {
            for (const [key, v] of Object.entries(value.value[0])) {
                new_item[key] = null;
            }
        }
    }

    let repeater_index = value.value.push(new_item);
    handleSubPanelOpenWithProps({repeater_index: repeater_index - 1});
}

/**
 * remove from list
 * @Todo: Confirm? Delete Assets?
 * @param index
 */
function removeFromList(index) {
    value.value.splice(index, 1);
}
</script>
