import {defineStore} from 'pinia';
import * as api from "../services/api";
import jp from "jsonpath";


export const useComponentEditStore = defineStore('ComponentEditStore', {
    state: () => {
        return {
            processing: false,
            showEditDialog: false,
            label: null,
            campaign: {},
            content: {},
            editingField: 'content',
            editor_config: {},
            window_items: [],
            current_window: 0,
            current_window_props: null,
            window_items_opened: [],
            window_props_history: [],
        };
    },
    getters: {},
    actions: {
        reset() {
            this.processing = false;
            this.showEditDialog = false;
            this.label = null;
            this.content = {};
            this.editingField = 'content';
            this.editor_config = {};
            this.window_items = [];
            this.current_window = 0;
            this.current_window_props = null;
            this.window_items_opened = [];
            this.window_props_history = [];
        },
        parseEditorConfig() {
            try {
                this.window_items = [];
                if (this.editor_config && this.editor_config.items) {
                    this.extractSubPanel(this.editor_config.items, this.window_items);
                } else {
                    this.editor_config = null;
                }
            } catch (e) {
                console.log(e);
                this.window_items = [];
            }

        },
        /**
         * extract all sub panels and create a flat array for v-window/v-window-item usage
         *
         * Recursive Function!
         *
         * @param elements
         * @param panels
         * @returns {number}
         */
        extractSubPanel(elements, panels) {
            const items = [];
            let panel_index = panels.push(items) - 1;

            elements.forEach(e => {
                if (e.component === 'SubPanel') {
                    items.push({
                        "component": "SubPanel",
                        "label": e.label,
                        "hint": e.hint,
                        "json_path": e.json_path,
                        "component_config": {
                            ...e.component_config,
                            "window_target_index": this.extractSubPanel(e.items, panels),
                        },
                        "vuetify_config": e.vuetify_config,
                    });
                } else {
                    items.push(e);
                }
            });

            return panel_index;
        },
        openSubPanel(panel_props) {
            this.window_items_opened.unshift(panel_props.window_index);
            this.current_window_props = panel_props;
            this.window_props_history.unshift(panel_props);

            // For Vuetify
            this.current_window = panel_props.window_index;
        },
        closeSubPanel() {
            // Shift current
            this.window_props_history.shift();
            this.window_items_opened.shift();

            // Set to previous settings
            this.current_window_props = this.window_props_history[0] || null;
            this.current_window = this.window_items_opened[0] || 0;
        },
        openEdit(patch_payload) {
            this.reset();
            this.$patch(patch_payload);
            this.showEditDialog = true;
            this.parseEditorConfig();
        },
        closeEdit() {
            this.showEditDialog = false;
        },
        save(jsonPath) {
            // Determine which field we are dealing with
            const parsed = jp.parse(jsonPath.value);
            const field = parsed[2].expression.value;

            return new Promise((resolve, reject) => {
                resolve();
            });
        },
        getAssetUploadUrl() {
            return window.route('assets.storeForCampaign', {campaign: this.campaign.id});
        },
        getAssetDeleteUrl(payload) {
            return window.route('assets.deleteCustom', {
                campaign: this.campaign.id,
                ...payload,
            });
        }
    }
});
