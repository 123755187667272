import { defineStore } from 'pinia';
import {usePage} from "@inertiajs/inertia-vue3";
import * as api from "../services/api";

export const useAppsStore = defineStore('AppsStore', {
    state: () => {
        return {
            all: [],
            categories: [],
            loading: true,
            open_panels: [],
            show: 'apps', // or coverpages
        };
    },
    getters: {
        currentCategories: (state) => {
            // Show all categories for a Operator
            if (usePage().props.value.auth.user.operator) return state.categories;

            if (state.show === 'coverpages') {
                state.open_panels = [7];
                return state.categories.filter(c => c.id === 7);
            }
            return state.categories.filter(c => c.id !== 7);
        }
    },
    actions: {
        loadApps() {
            this.loading = true;

            return new Promise((resolve, reject) => {
                // Prevent needless double loading!
                if (this.all.length > 0) {
                    this.loading = false;
                    resolve();
                    return;
                }

                const url = window.route('applications.forStepSelection', {account: usePage().props.value.account.id});
                api.client.get(url).then(response => {
                    this.all = response.data.apps;
                    this.categories = response.data.categories;
                    this.loading = false;
                    resolve();
                }).catch(error => {
                    console.log(error);
                    reject();
                });
            });
        }
    }
});
