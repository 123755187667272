import ActionField from "../components/editor-fields/ActionField";
import AlertField from "../components/editor-fields/AlertField";
import BooleanField from "../components/editor-fields/BooleanField";
import ColorField from "../components/editor-fields/ColorField";
import ComboboxField from "../components/editor-fields/ComboboxField";
import FieldGroup from "../components/editor-fields/FieldGroup";
import PdfUploadField from "../components/editor-fields/PdfUploadField";
import FieldRepeater from "../components/editor-fields/FieldRepeater";
import ImageField from "../components/editor-fields/ImageField";
import ImageRepeaterField from "../components/editor-fields/ImageRepeaterField";
import IngredientField from "../components/editor-fields/IngredientField";
import MediaField from "../components/editor-fields/MediaField";
import MediaRepeaterField from "../components/editor-fields/MediaRepeaterField";
import NullField from "../components/editor-fields/NullField";
import NumberField from "../components/editor-fields/NumberField";
import QuizAnswersField from "../components/editor-fields/QuizAnswersField";
import RepeaterField from "../components/editor-fields/RepeaterField";
import RichTextField from "../components/editor-fields/RichTextField";
import SelectField from "../components/editor-fields/SelectField";
import StringField from "../components/editor-fields/StringField";
import SubPanel from "../components/editor-fields/SubPanel";
import TextAreaField from "../components/editor-fields/TextAreaField";
import TextField from "../components/editor-fields/TextField";

/**
 *
 * @param fieldName
 * @param repeatable
 * @returns {string|ComponentDefinition}
 */
export function getEditorField(fieldName, repeatable) {
    switch (fieldName.toLowerCase()) {
        case 'textfield': return TextField;
        case 'textareafield': return TextAreaField;

        case 'checkboxfield':
        case 'checkbox':
        case 'booleanfield': return BooleanField;

        case 'comboboxfield': return ComboboxField;

        case 'mediafield':
            if (repeatable) {
                return MediaRepeaterField;
            }
            return MediaField;

        case 'pdffile':
        case 'pdfupload':
        case 'pdfuploadfield': return PdfUploadField;

        case 'imagefield':
            if (repeatable) return ImageRepeaterField;
            return ImageField;
        case 'subpanel': return SubPanel;

        case 'ingredientfield': return IngredientField;

        case 'quizanswer':
        case 'quizanswers':
        case 'answerfield':
        case 'answersfield':
        case 'quizanswersfield':
        case 'quizanswerfield': return QuizAnswersField;

        case 'actionbutton':
        case 'actionbuttonfield':
        case 'actionfield': return ActionField;

        case 'repeaterfield': return RepeaterField;
        case 'colorfield': return ColorField;

        case 'richtext' :
        case 'tiptap' :
        case 'richtextfield': return RichTextField;

        case 'select':
        case 'selectfield': return SelectField;

        case 'alert':
        case 'alertfield': return AlertField;
    }
}

export function niceDateTime(timestamp) {
    if ( ! timestamp) return null;
    const date = new Date(timestamp);
    const mediumTime = new Intl.DateTimeFormat("de", {
        timeStyle: "medium",
        dateStyle: "medium"
    });
    return mediumTime.format(date);
}

export function niceDate(timestamp) {
    if ( ! timestamp) return null;
    const date = new Date(timestamp);
    const mediumTime = new Intl.DateTimeFormat("de", {
        dateStyle: "medium"
    });
    return mediumTime.format(date);
}

export function niceTime(timestamp) {
    if ( ! timestamp) return null;
    const date = new Date(timestamp);
    const mediumTime = new Intl.DateTimeFormat("de", {
        timeStyle: "medium",
    });
    return mediumTime.format(date);
}

export function niceNumber(num) {
    return new Intl.NumberFormat('de-DE').format(num);
}

export function negotiateEditorField(value) {
    switch (typeof value) {
        case 'object':  if (Array.isArray(value)) return FieldRepeater;
            if (value === null) return NullField; // null --> https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/typeof#typeof_null
            return FieldGroup; // Object

        case 'boolean': return BooleanField;

        case 'number':
        case 'bigint': return NumberField;

        case 'string':
            const isImage = /(.*\.gif|\.jpe?g|\.png|\.svg)$/gi;
            if (value.match(isImage)) {
                return ImageField;
            }

            return StringField;

        case 'undefined':
        case 'function': return NullField;
    }
}

export function getInitials(name) {
    return name.trim().split(' ').map(s => s[0]).join('').toUpperCase();
}

// @see: https://codepen.io/scp-nm/pen/rNVKBVO
export function getColorFor(name) {
    let hash = 0;
    if (name.length === 0) return hash;
    for (let i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 255;
        color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
}

export function humanFileSize(bytes, si) {
    let thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }
    let units = si
        ? ['kB','MB','GB','TB','PB','EB','ZB','YB']
        : ['KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];
    let u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1)+' '+units[u];
}

// @see: https://stackoverflow.com/a/264180/5466203
export function parseBool(value)
{
    if (typeof value === "boolean") return value;
    const regex=/^\s*(true|1|on|y|yes|j|ja)\s*$/i;
    return regex.test(value);
}
