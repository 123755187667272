import cloneDeep from 'lodash.clonedeep';

/**
 * reset a store which was created with function syntax
 *
 * @see: https://dev.to/the_one/pinia-how-to-reset-stores-created-with-functionsetup-syntax-1b74
 * @param store
 */
export default function resetStore({ store }) {
    const initialState = cloneDeep(store.$state);
    store.$reset = () => store.$patch(cloneDeep(initialState));
}
