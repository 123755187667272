import { defineStore } from 'pinia';
import * as api from "../services/api";
import {nextTick} from "vue";
import {useStepEditStore} from "./StepEditStore";
import {usePresenceStore} from "./PresenceStore";
import {useCampaignStore} from "./CampaignStore";

export const useStepsStore = defineStore('StepsStore', {
    state: () => {
        return {
            all: {},
            loading: true,
            editingUuid: null,
            showEditDialog: false,
            preview_url: null,
            loadingStep: false,
            last_edited_step: null,
        };
    },
    getters: {
        byID: (state) => {
            return (id) => state.all[id];
        },
        showPreviewDialog: (state) => state.preview_url !== null,
    },
    actions: {
        /**
         * create a new Step on the server for the given Campaign and App
         *
         * @param payload {campaign_id, app_id, field, index}
         * @returns {*}
         */
        createOnServer(payload) {
            return new Promise((resolve, reject) => {
                const url = window.route('steps.store');
                api.client.post(url, payload).then(response => {
                    const step = response.data;
                    this.all[step.uuid] = step;
                    resolve(step);
                }).catch(error => {
                    console.log(error);
                    reject();
                });
            });
        },
        replicateOnServer(payload) {
            return new Promise((resolve, reject) => {
                const url = window.route('steps.replicate', {step: payload.uuid, index: payload.index});
                api.client.get(url).then(response => {
                    const step = response.data;
                    this.all[step.uuid] = step;
                    resolve(step);
                }).catch(error => {
                    console.log(error);
                    reject();
                });
            });
        },
        deleteOnServer(payload) {
            return new Promise((resolve, reject) => {
                const url = window.route('steps.destroy', {step: payload.uuid, field: payload.field, index: payload.index});
                api.client.delete(url).then(response => {
                    const uuid = response.data.uuid;
                    delete this.all[uuid];
                    resolve(uuid);
                }).catch(error => {
                    console.log(error);
                    reject();
                });
            });
        },
        editStep(uuid) {
            this.loadingStep = true;
            this.editingUuid = uuid;
            this.last_edited_step = null;
            this.showEditDialog = true;
            const stepEditStore = useStepEditStore();
            stepEditStore.$dispose();

            const campaignStore = useCampaignStore();
            campaignStore.closeSnackbar();

            return new Promise((resolve, reject) => {
                const url = window.route('steps.edit', {step: uuid});
                api.client.get(url).then(response => {
                    stepEditStore.reset();
                    stepEditStore.$patch(response.data);
                    stepEditStore.parseEditorConfig();
                    resolve();
                }).catch(error => {
                    console.log(error);
                    reject();
                });
            });
        },
        closeStepEdit() {
            usePresenceStore().fieldBlurred(this.editingUuid);
            this.loadingStep = false
            this.last_edited_step = this.editingUuid;
            this.editingUuid = null;
            this.showEditDialog = false;
        },
        updateStepInStore(updatedStep) {
            this.all[updatedStep.uuid] = updatedStep;
        },
        /**
         * update the editor_config of the given app
         *
         * @returns {*}
         */
        updateEditorConfig() {
            const stepEditStore = useStepEditStore();
            const app_id = this.all[this.editingUuid].app_id;
            const url = window.route('applications.updateEditorConfig', {application: app_id});
            const payload = {
                editor_config: stepEditStore.editor_config
            };

            return new Promise((resolve, reject) => {
                api.client.patch(url, payload).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    console.log(error);
                    reject();
                });
            });
        },

        /**
         * received Step Created Event
         * - someone else has just created a step!
         *
         * @param event
         */
        receivedStepCreated(event) {
            // Event has the new step and the updated campaign schedule
            this.all[event.step.uuid] = event.step;
            // Update Campaign Schedule
            useCampaignStore().campaign.schedule = event.schedule;
        },

        /**
         * received Main Step Created Event
         * - someone else just changed/created the main step
         *
         * @param event
         */
        receivedMainStepCreated(event) {
            // Event has the new step and the updated campaign schedule
            this.all[event.step.uuid] = event.step;
            // Update Campaign Schedule
            useCampaignStore().campaign.main = event.main;
        },

        /**
         * received Step Destroyed Event
         * - someone else has just deleted a step!
         *
         * @param event
         */
        receivedStepDestroyed(event) {
            delete this.all[event.uuid];
            // Update Campaign Schedule
            useCampaignStore().campaign.schedule = event.schedule;
        },

        // ----------------------------------------------------------------------------
        // Channel Events
        // ----------------------------------------------------------------------------
        bindChannelEvents(channel_name) {
            echoInstance.join(channel_name)
                .listen('StepCreated', this.receivedStepCreated)
                .listen('MainStepCreated', this.receivedMainStepCreated)
                .listen('StepDestroyed', this.receivedStepDestroyed);
        },

        unbindChannelEvents(channel_name) {
            echoInstance.join(channel_name)
                .stopListening('StepCreated', this.receivedStepCreated)
                .stopListening('MainStepCreated', this.receivedMainStepCreated)
                .stopListening('StepDestroyed', this.receivedStepDestroyed);
        },
    }
});
