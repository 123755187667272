<template>
    <v-card class="mb-6">
        <v-card-title>{{ props.label }}</v-card-title>
        <v-card-subtitle v-if="props.hint && props.hint !== props.label">{{ props.hint }}</v-card-subtitle>
        <v-card-text>
            <draggable :list="answers"
                       :item-key="((item) => answers.indexOf(item))"
                       handle=".drag-handle">
                <template #item="{element, index}">
                    <v-text-field v-model="answers[index].text"
                                  :label="answers[index].correct ? 'Richtige Antwort' : 'Falsche Antwort'"
                                  variant="underlined">
                        <template v-slot:prepend>
                            <v-icon class="drag-handle">mdi-drag-vertical</v-icon>
                        </template>
                        <template v-slot:append-inner>
                            <v-btn-toggle v-model="answers[index].correct" variant="outlined" mandatory>
                                <v-btn color="success"
                                       title="Diese Antwort als richtig markieren"
                                       :value="true">
                                    <v-icon>mdi-check</v-icon>
                                </v-btn>
                                <v-btn color="error"
                                       title="Diese Antwort als falsch markieren"
                                       :value="false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-btn-toggle>
                        </template>
                        <template v-slot:append>
                            <v-icon @click="removeFromList(index)" title="Entfernen">mdi-close</v-icon>
                        </template>
                    </v-text-field>

                </template>
            </draggable>
        </v-card-text>
        <v-card-actions>
            <v-btn icon="mdi-plus-circle"
                   title="Weiteres Element hinzufügen"
                   @click="addToList" color="primary"
                   class="mx-auto" />
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    inheritAttrs: false
}
</script>

<script setup>
import {computed} from "vue";
import draggable from "vuedraggable";
// https://www.npmjs.com/package/jsonpath
import jp from "jsonpath";
import {getStoreByName} from "../../utils/storeHelpers";
import {mandatory_props} from "../../utils/editorConfigFieldHelpers";

const props = defineProps(mandatory_props);
const store = getStoreByName(props.storeName);
/**
 * dynamic json_path!
 * If this Component lives *within* a repeatable SubPanel, we need to find the current index
 * The json_path from the config will not be complete, hence we build it here
 */
const jsonPath = computed(() => {
    if (store.current_window_props && store.current_window_props.json_path) {

        let my_json_path = [];
        if (props.json_path) {
            my_json_path = jp.parse(props.json_path).map(e => e.expression.value);
        }

        const path = [
            ...jp.parse(store.current_window_props.json_path).map(e => e.expression.value),
            store.current_window_props.repeater_index,
            ...my_json_path
        ];

        try {
            return jp.stringify(path);
        } catch (e) {
            console.log("Could not stringify json_path", ...path);
            console.log(e);
        }
    }

    return props.json_path;
});
const answers = computed({
    get() {
        return jp.value(store.content, jsonPath.value);
    },
    set(v) {
        jp.value(store.content, jsonPath.value, v);
    }
});

function addToList() {
    answers.value.push({
        text: null,
        correct: false,
    });
}
function removeFromList(index) {
    answers.value.splice(index, 1);
}
</script>

<style scoped>

</style>