import axios from 'axios';
import echoInstance from "./echo";

export const client = axios.create({
    baseURL: import.meta.env.VITE_APP_URL,
    withCredentials: true, // required to handle the CSRF token
});

/**
 * Register an Axios HTTP interceptor to add the X-Socket-ID header.
 * @see: https://stackoverflow.com/a/59491510/5466203
 */
client.interceptors.request.use((config) => {
    config.headers['X-Socket-ID'] = echoInstance.socketId(); // Echo instance
    // the function socketId () returns the id of the socket connection
    return config;
});

// @see: https://github.com/garethredfern/laravel-vue/blob/main/src/services/API.js
// client.interceptors.response.use(response => response, error => {
//     console.log(error);
//     return Promise.reject(error);
// });

