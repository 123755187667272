import { createApp, h } from 'vue';
import { createInertiaApp, Link } from '@inertiajs/inertia-vue3';
import { InertiaProgress } from '@inertiajs/progress';
import vuetify from './plugins/vuetify';
import { createPinia } from "pinia";
import resetStore from "./Stores/Plugins/reset-store";
import route from './plugins/route';
import Layout from "./Shared/AppLayout";
import jp from "jsonpath";

window.jp = jp;


// axios configuration: globals/services/api.js
// laravel-echo configuration: globals/services/socket.js

export async function importPageComponent(name, pages, defaultLayout) {
    // eslint-disable-next-line no-restricted-syntax
    for (const path in pages) {
        if (path.endsWith(`${name.replaceAll('.', '/')}.vue`)) {
            const component = typeof pages[path] === 'function'
                ? await pages[path]()
                : pages[path];
            // Set default layout
            component.default.layout ??= defaultLayout;
            return component;
        }
    }

    throw new Error(`Page not found: ${name}`);
}


InertiaProgress.init();
createInertiaApp({
    resolve: (name) => importPageComponent(name, import.meta.glob('./Pages/**/*.vue'), Layout),
    setup({ el, App, props, plugin }) {
        const app = createApp({
            render: () => h(App, props)
        });

        const pinia = createPinia();
        pinia.use(resetStore);
        window.pinia = pinia;

        app.component("inertia-link", Link)
            .use(plugin)
            .use(pinia)
            .use(route)
            .use(vuetify)
            .mount(el);
    },
});
