<template>
<v-checkbox v-model="value" :label="props.label" v-bind="props.vuetify_config"></v-checkbox>
</template>

<script setup>
import {computed} from "vue";
// https://www.npmjs.com/package/jsonpath
import jp from "jsonpath";
import {parseBool} from "../../utils/helpers";
import {mandatory_props} from "../../utils/editorConfigFieldHelpers";
import {getStoreByName} from "../../utils/storeHelpers";

const props = defineProps(mandatory_props);
const store = getStoreByName(props.storeName);

/**
 * dynamic json_path!
 * If this Component lives *within* a repeatable SubPanel, we need to find the current index
 * The json_path from the config will not be complete, hence we build it here
 */
const jsonPath = computed(() => {
    if (store.current_window_props && store.current_window_props.json_path) {

        let my_json_path = [];
        if (props.json_path) {
            my_json_path = jp.parse(props.json_path).map(e => e.expression.value);
        }

        const path = [
            ...jp.parse(store.current_window_props.json_path).map(e => e.expression.value),
            store.current_window_props.repeater_index,
            ...my_json_path
        ];

        try {
            return jp.stringify(path);
        } catch (e) {
            console.log("Could not stringify json_path", ...path);
            console.log(e);
        }
    }

    return props.json_path;
});

const value = computed({
    get() {
        return parseBool(jp.value(store.content, jsonPath.value));
    },
    set(v) {
        jp.value(store.content, jsonPath.value, parseBool(v));
    }
});
</script>

<style scoped>

</style>