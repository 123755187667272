<template>
    <v-card class="mb-6">
        <v-card-title>{{ props.label }}</v-card-title>
        <v-card-subtitle v-if="props.hint && props.hint !== props.label">{{ props.hint }}</v-card-subtitle>
        <v-card-text>
            <draggable :list="items"
                       :item-key="((item) => items.indexOf(item))" handle=".drag-handle">
                <template #item="{element, index}">
                    <image-field :json_path="`${props.json_path}[${index}]`"
                                 :label="`${props.label} ${index}`"
                                 :repeatable-child="true"
                                 @deleted="handleDeleted" />
                </template>
            </draggable>
        </v-card-text>
        <v-card-actions>
            <v-btn icon="mdi-plus-circle"
                   title="Weiteres Element hinzufügen"
                   @click="addToList" color="primary"
                   class="mx-auto" />
        </v-card-actions>
    </v-card>
</template>

<script setup>
import {computed, ref} from "vue";
import draggable from "vuedraggable";
import ImageField from "./ImageField";
// https://www.npmjs.com/package/jsonpath
import jp from "jsonpath";
import {mandatory_props} from "../../utils/editorConfigFieldHelpers";
import {useStepEditStore} from "../../Stores/StepEditStore";

const stepEdit = useStepEditStore();
const props = defineProps(mandatory_props);

const items = computed({
    get() {
        return jp.value(stepEdit.content, props.json_path);
    },
    set(v) {
        jp.value(stepEdit.content, props.json_path, v);
    }
});
function addToList() {
    items.value.push("");
}
function removeFromList(index) {
    items.value.splice(index, 1);
}
function handleDeleted(json_path) {
    const index = jp.parse(json_path).pop().expression.value;
    removeFromList(index);
    stepEdit.save();
}
</script>

<style scoped>

</style>
