<template>
    <v-menu v-if="myself">
        <template v-slot:activator="{ props }">
            <v-btn icon v-bind="props">
            <v-avatar :class="(customClass) ? customClass : ''"
                      :color="user.avatar_path ? null : getColorFor(userName)"
                      :size="size"
                      :title="myself ? 'Das bin ich' : userName">
                <v-img v-if="user.avatar_path"
                       :src="`${user.avatar_path}`"
                       :alt="user.name" cover aspect-ratio="1/1" />
                <span v-else class="text-white">
                    {{ getInitials(userName) }}
                </span>
            </v-avatar>
            </v-btn>
        </template>

        <v-list>
            <v-list-item @click.prevent="triggerInertiaVisit('user.profile.edit')" :href="$route('user.profile.edit')">
                <template v-slot:prepend>
                    <v-icon>mdi-card-account-details-outline</v-icon>
                </template>
                <v-list-item-title>Mein Profil</v-list-item-title>
            </v-list-item>
            <v-list-item @click.prevent="triggerInertiaVisit('logout', null, {method:'post'})" 
                         :href="$route('logout')">
                <template v-slot:prepend>
                    <v-icon>mdi-logout</v-icon>
                </template>
                <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
    <v-avatar v-else
              :class="(customClass) ? customClass : ''"
              :color="user.avatar_path ? null : getColorFor(userName)"
              :size="size"
              :title="myself ? 'Das bin ich' : userName">
        <v-img v-if="user.avatar_path"
             :src="`${user.avatar_path}`"
             :alt="user.name" cover aspect-ratio="1/1" />
        <span v-else class="text-white">
            {{ getInitials(userName) }}
        </span>
    </v-avatar>
</template>

<script setup>
import {defineProps, computed} from "vue";
import {usePage} from "@inertiajs/inertia-vue3";
import {triggerInertiaVisit} from "../utils/inertiaHelpers";
import {getInitials, getColorFor} from "../utils/helpers";

const props = defineProps({
    user: Object,
    customClass: String,
    size: {
        type: String,
        default: 'default'
    }
});

const myself = computed(() => {
    return props.user.id === usePage().props.value.auth.user.id;
});

const userName = computed(() => {
    if (props.user.fullName) return props.user.fullName;
    return props.user.first_name + ' ' + props.user.last_name;
})
</script>

<style lang="scss">
.v-avatar {
    .v-img {
        img {
            padding: 0 !important;
        }
    }
}
</style>
