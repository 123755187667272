<template>
<h3>{{ props.label }}</h3>

    <v-btn>
        <v-icon>mdi-plus</v-icon>
    </v-btn>
</template>

<script setup>
import {computed} from "vue";
// https://www.npmjs.com/package/jsonpath
import jp from "jsonpath";
import {mandatory_props} from "../../utils/editorConfigFieldHelpers";
import {useStepEditStore} from "../../Stores/StepEditStore";

const stepEdit = useStepEditStore();
const props = defineProps(mandatory_props);
const value = computed({
    get() {
        return jp.value(stepEdit.content, props.json_path);
    },
    set(v) {
        jp.value(stepEdit.content, props.json_path, v);
    }
});
</script>

<style scoped>

</style>