import Echo from 'laravel-echo';
import pusher from 'pusher-js';

const echoInstance = new Echo({
    broadcaster: 'pusher',
    key: "app-key-o2M9QEdlCPLGupuK1tXQbq1Vy4jLUtqG",
    wsHost: import.meta.env.VITE_WSHOST || "socket.happymo.re",
    wsPort: import.meta.env.VITE_WSPORT || 80,
    wssPort: import.meta.env.VITE_WSSPORT || 443,
    forceTLS: true,
    encrypted: true,
    disableStats: true,
    enabledTransports: ['ws'],
});

// For debug/easy console access
window.echoInstance = echoInstance;

export default echoInstance;