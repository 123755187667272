import { Inertia } from '@inertiajs/inertia';

export function route(name, params) {
    let my_params = params || {};
    return window.route(name, {...my_params});
}

export function triggerInertiaVisit(routeName, routeParams, visitParams) {
    Inertia.visit(route(routeName, routeParams), visitParams);
}
