import { defineStore } from 'pinia';
import * as api from "../services/api";
import jp from "jsonpath";
import route from "../utils/route";

export const useCampaignStore = defineStore('CampaignStore', {
    state: () => {
        return {
            campaign: {},
            errors: {}, // Key:Value of json_path to errors
            loading: true,
            showSnackbar: false,
            snackbarText: null,
        };
    },
    getters: {
        getErrors: (state) => (path) => state.errors[path]?.errors || [],
        getOriginalValue: (state) => (path) => state.errors[path].value,
    },
    actions: {
        init(campaign) {
            this.campaign = campaign;
        },

        /**
         * patch value in store
         * - Clear stale error messages
         *
         * @param {Object}  data
         * @param {string}  data.json_path
         * @param {*}       data.value
         */
        patchInStore(data) {
            jp.value(this, data.json_path, data.value);
            delete this.errors[data.json_path];
        },

        updateMeta() {
            return new Promise((resolve, reject) => {
                const url = window.route('campaigns.update', {campaign: this.campaign.id});
                const payload = {
                    field: 'meta',
                    value: this.campaign.meta,
                };

                api.client.patch(url, payload).then(response => {
                    // update store
                    this.campaign.meta = response.data.value;
                    this.snackbarText = 'Änderung gespeichert';
                    this.showSnackbar = true;
                    resolve(response.data.value); // the new value
                }).catch(error => {
                    if (error.response?.data?.value) {
                        this.campaign.schedule = error.response.data.value;
                    }
                    reject();
                });
            });
        },

        updateSchedule() {
            return new Promise((resolve, reject) => {
                const url = window.route('campaigns.update', {campaign: this.campaign.id});
                const payload = {
                    field: 'schedule',
                    value: this.campaign.schedule,
                };

                api.client.patch(url, payload).then(response => {
                    // update store
                    this.campaign.schedule = response.data.value;
                    resolve(response.data.value); // the new value
                }).catch(error => {
                    if (error.response?.data?.value) {
                        this.campaign.schedule = error.response.data.value;
                    }
                    reject();
                });
            });
        },

        updateComponents() {
            return new Promise((resolve, reject) => {
                const url = window.route('campaigns.update', {campaign: this.campaign.id});
                const payload = {
                    field: 'components',
                    value: this.campaign.components,
                };

                api.client.patch(url, payload).then(response => {
                    // update store
                    this.campaign.components = response.data.value;
                    this.snackbarText = 'Änderung gespeichert';
                    this.showSnackbar = true;
                    resolve(response.data.value); // the new value
                }).catch(error => {
                    if (error.response?.data?.value) {
                        this.campaign.components = error.response.data.value;
                    }
                    reject();
                });
            });
        },

        closeSnackbar() {
            this.snackbarText = null;
            this.showSnackbar = false;
        },

        // ----------------------------------------------------------------------------
        // Channel Events
        // ----------------------------------------------------------------------------
        bindChannelEvents(channel_name) {
            echoInstance.join(channel_name)
                .listen('CampaignUpdated', this.patchInStore);
        },

        unbindChannelEvents(channel_name) {
            echoInstance.join(channel_name)
                .stopListening('CampaignUpdated', this.patchInStore);
        },

        // ----------------------------------------------------------------------------
        // Sync with Server
        // ----------------------------------------------------------------------------
        syncWithServer(json_path) {
            // Check if the change is inside the schedule.
            // If so, just run updateSchedule instead of this function
            const parsed = jp.parse(json_path);
            const forSchedule = parsed.findIndex(e => e.expression.value === 'schedule');
            if (forSchedule >= 0) {
                return this.updateSchedule();
            }

            const payload = {
                json_path: json_path,
                value: jp.value(this, json_path),
            };

            return new Promise((resolve, reject) => {
                const url = route('campaigns.update', {campaign: this.campaign.id});
                api.client.patch(url, payload).then(response => {
                    // remove existing errors
                    delete this.errors[response.data.json_path];
                    resolve(response.data);
                }).catch(error => {
                    // Save the error Messages
                    this.errors[error.response.data.json_path] = {
                        value: error.response.data.value,
                        errors: [error.response.data.errors[json_path]],
                    };
                    reject(error.response.data);
                });
            });
        },
    }
});
