<template>
    <v-btn-toggle v-if="editor" v-model="formatting"
                  multiple variant="outlined" divided class="mb-2">

        <v-btn @click="editor.chain().focus().toggleBold().run()"
               :active="editor.isActive('bold')" size="x-small">
            <v-icon icon="mdi-format-bold"></v-icon>
        </v-btn>

        <v-btn @click="editor.chain().focus().toggleItalic().run()"
               :active="editor.isActive('italic')" size="x-small">
            <v-icon icon="mdi-format-italic"></v-icon>
        </v-btn>

        <v-btn @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
               :active="editor.isActive('heading', { level: 1 })" size="x-small">
            <v-icon icon="mdi-format-header-1"></v-icon>
        </v-btn>

        <v-btn @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
               :active="editor.isActive('heading', { level: 2 })" size="x-small">
            <v-icon icon="mdi-format-header-2"></v-icon>
        </v-btn>

        <v-btn @click="setLink"
               :active="editor.isActive('link')" size="x-small">
            <v-icon icon="mdi-link-variant"></v-icon>
        </v-btn>

        <v-btn @click="editor.chain().focus().unsetLink().run()"
               :disabled="!editor.isActive('link')" size="x-small">
            <v-icon icon="mdi-link-variant-off"></v-icon>
        </v-btn>

        <v-btn @click="editor.chain().focus().setParagraph().run()"
               :active="editor.isActive('paragraph')" size="x-small">
            <v-icon icon="mdi-format-paragraph"></v-icon>
        </v-btn>

        <v-btn @click="editor.chain().focus().toggleBulletList().run()"
               :active="editor.isActive('bulletList')" size="x-small">
            <v-icon icon="mdi-format-list-bulleted"></v-icon>
        </v-btn>

        <v-btn @click="editor.chain().focus().toggleOrderedList().run()"
               :active="editor.isActive('orderedList')" size="x-small">
            <v-icon icon="mdi-format-list-numbered"></v-icon>
        </v-btn>

        <v-btn @click="editor.chain().focus().setHorizontalRule().run()" size="x-small">
            <v-icon icon="mdi-minus"></v-icon>
        </v-btn>

        <v-btn @click="editor.chain().focus().undo().run()" size="x-small">
            <v-icon icon="mdi-undo"></v-icon>
        </v-btn>

        <v-btn @click="editor.chain().focus().redo().run()" size="x-small">
            <v-icon icon="mdi-redo"></v-icon>
        </v-btn>

    </v-btn-toggle>
    <editor-content :editor="editor" />

</template>

<script>
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import Typography from '@tiptap/extension-typography';
import { Editor, EditorContent } from '@tiptap/vue-3';

/**
 * @see: https://tiptap.dev/api/nodes
 * @see: https://tiptap.dev/api/marks
 */

export default {
    components: {
        EditorContent,
    },

    props: {
        modelValue: {
            type: String,
            default: '',
        },
    },

    emits: ['update:modelValue'],

    data() {
        return {
            editor: null,
            formatting: [],
        }
    },

    watch: {
        modelValue(value) {
            // HTML
            const isSame = this.editor.getHTML() === value

            // JSON
            // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

            if (isSame) {
                return
            }

            this.editor.commands.setContent(value, false)
        },
    },

    methods: {
        setLink() {
            const previousUrl = this.editor.getAttributes('link').href;
            const url = window.prompt('URL', previousUrl);

            // cancelled
            if (url === null) {
                return;
            }

            // empty
            if (url === '') {
                this.editor
                    .chain()
                    .focus()
                    .extendMarkRange('link')
                    .unsetLink()
                    .run();
                return;
            }

            // update link
            this.editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .setLink({ href: url })
                .run();
        }
    },

    mounted() {
        this.editor = new Editor({
            extensions: [
                StarterKit,
                Link.configure({
                    openOnClick: false,
                }),
                Typography,
            ],
            content: this.modelValue,
            onUpdate: () => {
                // HTML
                this.$emit('update:modelValue', this.editor.getHTML())

                // JSON
                // this.$emit('update:modelValue', this.editor.getJSON())
            },
        })
    },

    beforeUnmount() {
        this.editor.destroy()
    },
}
</script>
<style>
.ProseMirror {
    min-height: 12rem;
    padding: 0.5em;
    border-color: rgba(var(--v-border-color), var(--v-border-opacity));
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2),
                0 0 0 0 rgba(0, 0, 0, 0.14),
                0 0 0 0 rgba(0, 0, 0, 0.12);
}
.ProseMirror-focused {
    border:1px solid #CCC;
    outline: none;
}
</style>