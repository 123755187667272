import { ref } from "vue";
import {usePage} from "@inertiajs/inertia-vue3";
import * as api from "../services/api";
import echoInstance from "../services/echo";

export function useGlobalPresenceChannel() {
    const present_user_ids = ref([]);
    const present_users = ref([]);

    function here(users) {
        present_user_ids.value = users;

        if (usePage().props.value.auth.user.operator) {
            api.client.get(route('users.forPresenceChannel', {users: users}))
                .then(response => {
                    present_users.value = response.data.users;
                });
        }
    }

    function joining(user) {
        present_user_ids.value.push(user);

        if (usePage().props.value.auth.user.operator) {
            api.client.get(route('users.forPresenceChannel', {users: [user]}))
                .then(response => {
                    present_users.value.push(response.data.users[0]);
                });
        }
    }

    function leaving(user) {
        let index = present_user_ids.value.indexOf(user);
        present_user_ids.value.splice(index, 1);

        if (usePage().props.value.auth.user.operator) {
            let i = present_users.value.findIndex(u => u.id === user);
            present_users.value.splice(i, 1);
        }
    }

    function error(error) {
        console.error(error);
    }

    echoInstance.join('global.presence')
        .here(here)
        .joining(joining)
        .leaving(leaving)
        .error(error);

    return {
        present_user_ids,
        present_users,
    };
}
