<template>
    <v-card class="mt-4">
        <v-card-text>
            <v-row>
                <v-col cols="6">
                    <v-text-field v-model="text"
                                  :label="props.label || 'Text auf Button'"
                                  :hint="props.hint || 'Dieser Text ist für den Benutzer sichtbar'"
                                  :disabled="type === 'hidden'"
                                  variant="underlined" />

                    <v-text-field v-model="href" v-if="type === 'external'"
                                  label="HREF" type="url"
                                  :disabled="type === 'hidden'"
                                  variant="underlined"
                                  :rules="[required, url]" />

                    <v-text-field v-model="href" v-if="type === 'internal'"
                                  label="HREF"
                                  :disabled="type === 'hidden'"
                                  variant="underlined"
                                  :rules="[required]" />
                </v-col>
                <v-col cols="6">
                    <v-btn-toggle v-model="type" variant="outlined" color="primary">
                        <v-btn value="next" title="Es wird die nächste App aufgerufen">
                            <v-icon>mdi-arrow-right</v-icon>
                        </v-btn>

                        <v-btn value="internal" title="Interne Verlinkung">
                            <v-icon>mdi-arrow-down-right</v-icon>
                        </v-btn>

                        <v-btn value="external" title="Button ruft externe Url auf">
                            <v-icon>mdi-link-variant</v-icon>
                        </v-btn>

                        <v-btn value="hidden" title="Versteckt / Nicht sichtbar">
                            <v-icon>mdi-eye-off-outline</v-icon>
                        </v-btn>
                    </v-btn-toggle>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script setup>
import {computed} from "vue";
import {required, url} from "../../utils/formValidationRules";
// https://www.npmjs.com/package/jsonpath
import jp from "jsonpath";
import {mandatory_props} from "../../utils/editorConfigFieldHelpers";
import {getStoreByName} from "../../utils/storeHelpers";

const props = defineProps(mandatory_props);
const store = getStoreByName(props.storeName);
/**
 * dynamic json_path!
 * If this Component lives *within* a repeatable SubPanel, we need to find the current index
 * The json_path from the config will not be complete, hence we build it here
 */
const jsonPath = computed(() => {
    if (store.current_window_props && store.current_window_props.json_path) {
        return jp.stringify([
            ...store.current_window_props.json_path.split('.'),
            store.current_window_props.repeater_index,
            props.json_path
        ]);
    }

    return props.json_path;
});
const href = computed({
    get() {
        return jp.value(store.content, jsonPath.value + '.href');
    },
    set(v) {
        jp.value(store.content, jsonPath.value + '.href', v);
    }
});
const text = computed({
    get() {
        return jp.value(store.content, jsonPath.value + '.text');
    },
    set(v) {
        jp.value(store.content, jsonPath.value + '.text', v);
    }
});
const type = computed({
    get() {
        return jp.value(store.content, jsonPath.value + '.type');
    },
    set(v) {
        jp.value(store.content, jsonPath.value + '.type', v);
    }
});
</script>

<style scoped>

</style>