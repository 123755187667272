<template>
    <v-card class="d-inline-block mr-4"
            @dragover.prevent="handleDragOver"
            @drop.prevent="handleFileDrop" min-width="100%">
        <v-card-title>{{ cardTitle }}</v-card-title>
        <v-card-subtitle v-if="props.hint">{{ props.hint }}</v-card-subtitle>
        <input type="file" ref="uploader" style="display: none" @change="handleFileSelect">

        <v-img v-if="type === 'image'"
               :src="`${base_url}${url}`"
               :lazy-src="`${base_url}/resources/redcode/img/favicon/apple-touch-icon.png`"
               @click="handleImageClick"
               v-bind="props.vuetify_config"
               max-height="300"
               cover :aspect-ratio="1" class="white--text align-end mx-auto">
            <div v-if="is_uploading" class="d-flex align-center justify-center fill-height">
                <!-- Progress Throbber while the asset is uploading -->
                <v-progress-circular :rotate="0"
                                     :size="50"
                                     :width="4"
                                     :model-value="upload_progress"
                                     color="primary">
                    {{ upload_progress || 0 }}%
                </v-progress-circular>
            </div>
        </v-img>
        <div v-else id="iframe-wrapper" :class="{}">
            <iframe :src="iFrameUrl" frameborder="0"></iframe>
            <v-text-field v-if="show_id_field" v-model="url" :label="inputLabel" variant="solo" @keyup.enter="show_id_field = false">
                <template v-if="url" v-slot:append-inner>
                    <v-icon @click="show_id_field = false">mdi-check</v-icon>
                </template>
            </v-text-field>
        </div>

        <v-card-actions>
            <v-btn v-if="type !== 'image'" @click="show_id_field = !show_id_field">
                <v-icon v-if="type === 'youtube'" title="YouTube ID bearbeiten">mdi-youtube</v-icon>
                <v-icon v-if="type === 'vimeo'" title="Vimeo ID bearbeiten">mdi-vimeo</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-menu>
                <template v-slot:activator="{ props }">
                    <v-btn icon="mdi-dots-vertical" size="x-small" variant="flat" class="ml-auto" v-bind="props">
                    </v-btn>
                </template>
                <v-list density="compact">
                    <v-list-item @click="toggleFileSelect">
                        <v-list-item-title :title="`Maximale Uploadgröße: ${humanFileSize(max_upload_size, true)}`">
                            <v-icon>mdi-tray-arrow-up</v-icon> Bild hochladen
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="toggleType('youtube')">
                        <v-list-item-title title="YouTube Video einbinden">
                            <v-icon>mdi-youtube</v-icon> YouTube
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="toggleType('vimeo')">
                        <v-list-item-title title="Vimeo Video einbinden">
                            <v-icon>mdi-vimeo</v-icon> Vimeo
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="isDeletable" @click="handleDeleteCustomised">
                        <v-list-item-title title="Hochgeladenes Bild löschen">Löschen</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-card-actions>

        <!-- File Size Upload Warnings. @Todo: Needs restyling? -->
        <ul v-if="warnings.length">
            <li v-for="(warning, index) in warnings" :key="index">
                {{ warning }} <v-btn @click="warnings.splice(index,1)" icon="mdi-close"></v-btn>
            </li>
        </ul>

    </v-card>
</template>

<script setup>
import {computed, ref, onMounted} from "vue";
import {humanFileSize} from "../../utils/helpers";
import * as api from "../../services/api";
// https://www.npmjs.com/package/jsonpath
import jp from "jsonpath";
import {mandatory_props} from "../../utils/editorConfigFieldHelpers";
import {getStoreByName} from "../../utils/storeHelpers";

defineExpose({toggleFileSelect});

const max_upload_size = 500000; // 500 KB
const warnings = ref([]);
const is_uploading = ref(false);
const uploader = ref(null);
const upload_progress = ref(0);
const show_id_field = ref(false);

const base_url = import.meta.env.VITE_DELIVERY_SYSTEM_URL;
const props = defineProps({
    ...mandatory_props,
    repeatableChild: {
        type: Boolean,
        default: false,
    }
});
const store = getStoreByName(props.storeName);
/**
 * dynamic json_path!
 * If this Component lives *within* a repeatable SubPanel, we need to find the current index
 * The json_path from the config will not be complete, hence we build it here
 */
const jsonPath = computed(() => {
    if (store.current_window_props && store.current_window_props.json_path) {

        let my_json_path = [];
        if (props.json_path) {
            my_json_path = jp.parse(props.json_path).map(e => e.expression.value);
        }

        const path = [
            ...jp.parse(store.current_window_props.json_path).map(e => e.expression.value),
            store.current_window_props.repeater_index,
            ...my_json_path
        ];

        try {
            return jp.stringify(path);
        } catch (e) {
            console.log("Could not stringify json_path", ...path);
            console.log(e);
        }
    }

    return props.json_path;
});

// ----------------------------------------------------------------------------
// Url and Type
// ----------------------------------------------------------------------------
const url = computed({
    get() {
        return jp.value(store.content, jsonPath.value + '.url');
    },
    set(v) {
        jp.value(store.content, jsonPath.value + '.url', v);
    }
});
const type = computed({
    get() {
        return jp.value(store.content, jsonPath.value + '.type');
    },
    set(v) {
        jp.value(store.content, jsonPath.value + '.type', v);
    }
});


const cardTitle = computed(() => {
    if (type.value === 'youtube') return 'YouTube Video';
    if (type.value === 'vimeo') return 'Vimeo Video';
    return 'Bild';
});
const inputLabel = computed(() => {
    return (type.value === 'youtube') ? 'YouTube-ID' : 'Vimeo-ID';
});
const iFrameUrl = computed(() => {
    if (type.value === 'youtube') {
        return `https://www.youtube-nocookie.com/embed/${url.value}?rel=0&amp;showinfo=0`;
    }
    if (type.value === 'vimeo') {
        return `https://player.vimeo.com/video/${url.value}?title=0&byline=0&portrait=0&badge=0`;
    }
});
const emits = defineEmits(['deleted']);

// ----------------------------------------------------------------------------
// Show Assets
// ----------------------------------------------------------------------------

function toggleType(new_type) {
    type.value = new_type;
    url.value = null;

    if (new_type !== 'image') {
        show_id_field.value = true;
    }
}

function handleImageClick() {
    // Click on placeholder image
    if ( ! Boolean(url.value).valueOf()) return toggleFileSelect();
    // click on "real" image
    handleShowCurrent();
}

/**
 * Show the current (customised) asset
 */
function handleShowCurrent() {
    const w = window.open(`${base_url}${url.value}`, '_blank');
    w.focus();
}

/**
 * Show the original asset
 */
function handleShowOriginal() {
    const url = window.route('assets.getOriginal', {
        step: store.uuid,
        json_path: jsonPath.value
    });

    api.client.get(url).then(response => {
        const w = window.open(`${base_url}${response.data.original_path}`, '_blank');
        w.focus();
    });
}

// ----------------------------------------------------------------------------
// File Upload
// ----------------------------------------------------------------------------
function toggleFileSelect() {
    type.value = 'image';
    url.value = null;
    uploader.value.click();
}
// Do not delete this function, it is needed for the drag and drop function!
function handleDragOver(event) { }
function handleFileDrop(event) {
    // Prevent default behavior (Prevent file from being opened)
    event.preventDefault();
    let source = event.dataTransfer.items || event.dataTransfer.files;

    // Use DataTransferItemList interface to access the file(s)
    for (let i = 0; i < source.length; i++) {
        // If dropped items aren't files, reject them
        if (source[i].kind === 'file') {
            let file = source[i].getAsFile();
            if (file.size < max_upload_size) {
                uploadFileForStep(file);
            } else {
                addWarning(file);
            }
        }
    }
}
function handleFileSelect(event) {
    for (let i = 0; i < event.target.files.length; i++) {
        let file = event.target.files[i];

        if (file.size < max_upload_size) {
            uploadFileForStep(file);
        } else {
            addWarning(file);
        }
    }
}
function uploadFileForStep(file) {
    const postUrl = window.route('assets.storeForStep', {step: store.uuid});
    const form = new FormData();
    form.append('asset', file);

    let config = {
        onUploadProgress: (progressEvent) => {
            upload_progress.value = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        }
    }

    is_uploading.value = true;
    api.client.post(postUrl, form, config).then(response => {
        // Overwrite Image Path with new path
        url.value = response.data.path;
        // Save the config
        store.save();
    }).catch(error => {
        // @Todo: How to handle Error?
    }).finally(() => {
        is_uploading.value = false;
    });
}
function addWarning(file) {
    warnings.value.push(`Die Datei "${file.name}" ist mit ${humanFileSize(file.size, true)} für den Upload zu groß!`);
}

// ----------------------------------------------------------------------------
// Delete / Restore
// ----------------------------------------------------------------------------

/**
 * Customised Assets are stored in the "/accounts/" folder
 *
 * @type {ComputedRef<*>}
 */
const isDeletable = computed(() => {
    return (type.value === 'image' && url.value);
});

/**
 * handle delete customised (asset).
 * - Tell the server to delete the customised asset
 * - "We" (the client) then overwrite the current image with the original_path
 *   and then tell the server to save the current config from the store store
 */
function handleDeleteCustomised() {
    // @Todo: Confirm delete!
    const actionUrl = window.route('assets.deleteCustomised', {
        step: store.uuid,
        json_path: jsonPath.value,
    });

    // If the image is "empty" just remove it from the list
    if (url.value === "") {
        emits('deleted', jsonPath.value);
        return;
    }

    api.client.delete(actionUrl).then(response => {
        if (response.data.original_path) {
            // Overwrite Image Path with original path
            url.value = response.data.original_path;
            // Save the config
            store.save();
        } else {
            // There is no original, which means we are probably inside a repeater field.
            // Inform ImageRepeaterField via event (it will remove this ImageField from the list)
            emits('deleted', jsonPath.value);
        }
    });
}
</script>

<style lang="scss" scoped>
    #iframe-wrapper {
        position: relative;

        iframe {
            width: 100%;
            height: 100%;
            min-height: 150px;
            aspect-ratio: 16/9;
            z-index: 0;
            position: relative;
        }
        .v-text-field {
            position: absolute;
            z-index: 100;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            min-width: 50%;
        }
    }
</style>