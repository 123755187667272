import { defineStore } from 'pinia';
import {usePage} from "@inertiajs/inertia-vue3";
import echoInstance from "../services/echo";
import {getStoreByName} from "../utils/storeHelpers";
import {getInitials} from "../utils/helpers";

export const usePresenceStore = defineStore('PresenceStore', {
    state: () => {
        return {
            channel_name: null,
            present_users: {},
            fields_focused_by: {},
            /**
             * which field is currently focused by *the current* user
             */
            current_user_focus: null,
        }
    },
    getters: {
        /**
         * current (auth) user
         *
         * @see Middleware/HandleInertiaRequest
         * @returns {*}
         */
        current_user() {
            return usePage().props.value.auth.user;
        },
        getFocusFor: (state) => (path) => state.fields_focused_by[path],
        getFocusUser: (state) => (path) => state.present_users[state.fields_focused_by[path]],
        /**
         * check if the given path is focused by anyone
         *
         * @param state
         * @returns {function(*): boolean}
         */
        isFocused: (state) => (path) => {
            return Boolean(state.fields_focused_by[path]).valueOf();
        },
        /**
         * get the edit hint for the focused field
         * -> use in conjunction with this.isFocused!
         *
         * @param state
         * @returns {function(*): string}
         */
        focusedBy: (state) => (path) => {
            return getInitials(state.present_users[state.fields_focused_by[path]].fullName) + ' schreibt ...';
        }
    },
    actions: {
        bindChannelEvents() {
            // USE this syntax!
            // A getter which returns "echoInstance.join(this.channel_name)" does not work
            echoInstance.join(this.channel_name)
                .listenForWhisper('field_focused', this.whisperReceivedFieldFocused)
                .listenForWhisper('field_changed', this.whisperReceivedFieldChanged)
                .listenForWhisper('field_blurred', this.whisperReceivedFieldBlurred);
        },
        unbindChannelEvents() {
            // USE this syntax!
            // A getter which returns "echoInstance.join(this.channel_name)" does not work
            echoInstance.join(this.channel_name)
                .stopListeningForWhisper('field_focused', this.whisperReceivedFieldFocused)
                .stopListeningForWhisper('field_changed', this.whisperReceivedFieldChanged)
                .stopListeningForWhisper('field_blurred', this.whisperReceivedFieldBlurred);
        },

        /**
         * the *current* user focused a field!
         * - Save the current own focus
         * - Inform everyone else on the same channel about the focus event
         *
         * @param path
         */
        fieldFocused(path) {
            this.current_user_focus = path;
            // USE this syntax!
            // A getter which returns "echoInstance.join(this.channel_name)" does not work
            echoInstance.join(this.channel_name)
                .whisper('field_focused', {path, user_id: this.current_user.id});
        },
        /**
         * someone else focused a field on the current channel
         * - save the focus in this store
         *
         * @param payload
         */
        whisperReceivedFieldFocused(payload) {
            this.fields_focused_by[payload.path] = payload.user_id;
        },

        /**
         * a field has changed but not synced with the server
         * - This is primarily used with UI toggles
         *
         * @param payload{json_path, value, store_name}
         */
        fieldChanged(payload) {
            echoInstance.join(this.channel_name).whisper('field_changed', payload);
        },

        /**
         *
         *
         * @param payload{json_path, value, store_name}
         */
        whisperReceivedFieldChanged(payload) {
            const store = getStoreByName(payload.store_name);
            store.patchInStore({
                json_path: payload.json_path,
                value: payload.value,
            });
        },

        /**
         * the *current* user blurred a field
         * - set current own focus to null
         * - inform everyone else on the current channel about the blur event
         *
         * @param path
         */
        fieldBlurred(path) {
            this.current_user_focus = null;
            // USE this syntax!
            // A getter which returns "echoInstance.join(this.channel_name)" does not work
            echoInstance.join(this.channel_name).whisper('field_blurred', path);
        },
        /**
         * someone else blurred a field
         * - remove saved focus from thie store
         *
         * @param path
         */
        whisperReceivedFieldBlurred(path) {
            delete this.fields_focused_by[path];
        },

        /**
         * a new user has joined the current presence channel
         * - Inform everyone about the current own focus state
         *
         * @see: resources/js/globals/utils/presenceChannel.js
         */
        syncFocused() {
            console.log("syncing focus!");
            // USE this syntax!
            // A getter which returns "echoInstance.join(this.channel_name)" does not work
            if (this.current_user_focus) {
                echoInstance.join(this.channel_name)
                    .whisper('field_focused', {
                        path: this.current_user_focus,
                        user_id: this.current_user.id
                    });
            }

            /*
            // Debug: Fake current focus
            if (this.current_user.id === "43dc0d3d-9e29-4422-bb08-b8c7868caa8f") {
                echoInstance.join(this.channel_name)
                    .whisper('field_focused', {
                        path: "$.all['43dc0d3d-4a7b-4a5a-bf7d-a66b4a6db5b4'].name",
                        user_id: this.current_user.id
                    });
            }
            */
        },
        /**
         * a user left the current channel
         * - Check if we still have a focus reference for the user
         * - remove the stale focus from the store
         *
         * @param user_id
         */
        clearStaleFocus(user_id) {
            for (let path in this.fields_focused_by) {
                if (this.fields_focused_by[path] === user_id) {
                    this.whisperReceivedFieldBlurred(path);
                    break;
                }
            }
        }
    }
});